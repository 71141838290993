import React, { useState } from 'react'
import firebase from "gatsby-plugin-firebase"
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import Modal from 'styled-react-modal'

import {
  ContainerMixin,
  RowMixin,
  ColMixin,
  PaddingsContainerMixin,
  Button,
} from './elements'

import semiCircleIcon from '../images/semicircle-gray.svg'

const ContactsWrapper = styled.div`
  position: relative;
  background-color: #00863d;
  color: ${(props) => props.theme.colorWhite};
  padding-top: 150px;
  padding-bottom: 40px;
  z-index: 80;
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 80px;
  }
  @media (max-width: 575px) {
    padding-top: 60px;
  }
`

const ContactsContainer = styled.div`
  ${ContainerMixin}
`

const ContactsRow = styled.div`
  ${RowMixin}
`

const ContactsCol = styled.div`
  ${ColMixin}
`

const ContactsColTitle = styled(ContactsCol)`
  flex: 0 0 100%;
  max-width: 100%;
  ${PaddingsContainerMixin}
`

const ContactsTitle = styled.h2`
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 35px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
`

const ContactsButton = styled(({ loading, ...rest }) => <Button {...rest} />)`
  position: relative;
  display: block;
  width: 220px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  ${props => props.loading ? `color: transparent;` : ``}

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 200px;
    margin-top: 30px;
  }
  @media (max-width: 991px) {
    margin-right: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 200px;
  }
  @media (max-width: 575px) {
    width: 180px;
  }
  &:hover {
    ${(props) =>
      props.loading &&
      `
      opacity: 1;
      cursor: default;
    `}
  }
  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    ${(props) =>
      !props.loading &&
      `
      display: none;
    `}
    .lds-spinner {
      color: official;
      display: inline-block;
      position: relative;
      width: 40px;
      height: 40px;
    }
    .lds-spinner div {
      transform-origin: 20px 20px;
      animation: lds-spinner 1.2s linear infinite;
    }
    .lds-spinner div:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 3px;
      left: 18.5px;
      width: 3px;
      height: 9px;
      border-radius: 20%;
      background: #fff;
    }
    .lds-spinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    .lds-spinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    .lds-spinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    .lds-spinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    .lds-spinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    .lds-spinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    .lds-spinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    .lds-spinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    .lds-spinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    .lds-spinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    .lds-spinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    .lds-spinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    @keyframes lds-spinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`

const ContactsFormWrapper = styled.div`
  ${RowMixin}
  padding-top: 50px;
`

const ContactsForm = styled(Form)`
  ${ColMixin}
  flex: 0 0 100%;
  max-width: 100%;
  ${PaddingsContainerMixin}
`

const ContactsFormColLeft = styled(ContactsCol)`
  padding-right: 4.9%;
  @media (min-width: 1600px) {
    padding-right: 4.9%;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-right: 4%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-right: 3%;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const ContactsFormColRight = styled(ContactsCol)`
  position: relative;
  z-index: 50;
  @media (min-width: 1600px) {
    padding-left: 4.9%;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 4%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 3%;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 25px;
  }
  &:before {
    content: "${(props) => props.customPlaceholder}";
    display: block;
    ${(props) => props.textValue && `display: none;`}
    height: 30px;
    position: absolute;
    left: 9.9%;
    right: 0;
    bottom: 0;
    font-family: ${(props) => props.theme.fontMetaPro};
    font-size: 20px;
    z-index: 10;
    @media (min-width: 1200px) and (max-width: 1599px) {
      left: 8%;
      bottom: -6px;
      font-size: 17px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      left: 6.4%;
      bottom: -10px;
      font-size: 14px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      left: 1.9%;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      left: 2.5%;
      bottom: -3px;
      font-size: 16px;
    }
    @media (max-width: 575px) {
      left: 4.7%;
      bottom: -10px;
      font-size: 12px;
    }
  }
`

const ContactsFormLabel = styled.label`
  display: inline-block;
  font-size: 18px;
  margin-bottom: 8px;
  cursor: pointer;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 16px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
`

const ContactsFormField = styled(Field)`
  width: 100%;
  background-color: transparent;
  color: #fff;
  /* ${(props) => props.error && 'color: red;'} */
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 20px;
  margin-bottom: 25px;
  border: none;
  border-bottom: 2px solid #fff;
  outline: none;
  ${(props) =>
    props.component === 'textarea' &&
    `
    height: calc(100% - 26px);
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 17px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
    ${(props) =>
      props.component === 'textarea' &&
      `
      height: calc(100% - 24px);
    `}
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: auto;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${(props) =>
      props.component === 'textarea' &&
      `
      min-height: 180px;
    `}
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${(props) =>
      props.component === 'textarea' &&
      `
      min-height: 160px;
    `}
    font-size: 16px;
  }
  @media (max-width: 575px) {
    ${(props) =>
      props.component === 'textarea' &&
      `
      min-height: 120px;
    `}
    font-size: 12px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &::placeholder {
    color: #fff;
  }
`

const ContactsColCall = styled.div`
  ${ColMixin}
  flex: 0 0 100%;
  max-width: 100%;
  ${PaddingsContainerMixin}
  padding-top: 170px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 150px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 140px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 80px;
  }
  @media (max-width: 575px) {
    padding-top: 60px;
  }
`

const ContactsCallWrapper = styled.div`
  position: relative;
  @media (min-width: 768px) {
    padding-left: 50px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -128px;
    left: -75px;
    display: block;
    width: 240px;
    height: 240px;
    background-image: url(${semiCircleIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media (min-width: 992px) and (max-width: 1199px) {
      top: -97px;
      left: -48px;
      width: 180px;
      height: 180px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      top: -88px;
      left: -36px;
      width: 160px;
      height: 160px;
    }
    @media (max-width: 767px) {
      content: none;
    }
  }
`

const ContactsCallTitle = styled.p`
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 50px;
  font-weight: 700;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 42px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 34px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 26px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
  }
`

const ContactsCallPhone = styled.a`
  display: inline-block;
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 54px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 25px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 50px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 38px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 32px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 32px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    font-size: 34px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
`

const ContactsCallNote = styled.p`
  font-family: ${(props) => props.theme.fontMetaPro};
  font-size: 22px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
`

const ContactsColCallAnnotation = styled.div`
  font-size: 20px;
  line-height: 1.2;
  padding-top: 80px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    padding-top: 60px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
    padding-top: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 14px;
    padding-top: 40px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
    padding-top: 35px;
  }
`

const ContactsModal = Modal.styled`
  width: 50%;
  background-color: ${(props) => props.theme.colorWhite};
  padding: 60px;
  position: relative;
  text-align: center;
  @media (min-width: 576px) and (max-width: 767px) {
    width: 80%;
    padding: 30px;
  }
  @media (max-width: 575px) {
    width: 80%;
    padding: 20px;
  }

  &:after{
    border: 3px solid ${(props) => props.theme.colorGreen};;
    bottom: 15px;
    content: '';
    display: block;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 0;
  }

  p {
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
`

const ContactsModalButton = styled(Button)`
  display: block;
  width: 220px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  cursor: pointer;
  @media (min-width: 1200px) {
    margin-top: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 200px;
    margin-top: 30px;
  }
  @media (max-width: 991px) {
    margin-right: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 200px;
    margin-bottom: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    width: 140px;
    font-size: 18px;
    margin-bottom: 15px;
  }
`

const Contacts = () => {
  const [fakePlaceholder, setFakePlaceholder] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSendingLoader, setIsSendingLoader] = useState(false)

  const setFakePlaceholderAction = (fakePlaceholder) => {
    setFakePlaceholder(!fakePlaceholder)
  }

  const setIsModalOpenAction = () => {
    setIsModalOpen(!isModalOpen)
  }

  const setSendingLoaderAction = (isSendingLoader) => {
    setIsSendingLoader(isSendingLoader)
  }

  return (
    <ContactsWrapper id="contacts">
      <ContactsContainer>
        <ContactsRow>
          <ContactsColTitle>
            <ContactsTitle>KONTAKT</ContactsTitle>
          </ContactsColTitle>
        </ContactsRow>
        <ContactsFormWrapper>
          <Formik
            initialValues={{ subject: '', email: '', company: '', content: '' }}
            validate={(values) => {
              const errors = {}

              for (let key in values) {
                if (!values[key]) {
                  errors[key] = 'Required'
                }
              }

              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address'
              }

              setFakePlaceholderAction(fakePlaceholder)
              setSendingLoaderAction(false)

              return errors
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSendingLoaderAction(true)
              setSubmitting(false)

              firebase
                .firestore()
                .collection('mails')
                .add({
                  to: ['info@repxpert.pl'],
                  message: {
                    subject: `[niedajsieprzeoczyc.pl] ${values.subject}`,
                    text: `Wiadomość kontaktowa z niedajsieprzeoczyc.pl\nFirma: ${values.company}\nE-mail: ${values.email}\n\n${values.content}`
                  }
                })
                .then(() => {
                  setIsModalOpenAction()
                  resetForm()
                  setSendingLoaderAction(false)
                })
                .catch(function (error) {})
            }}
          >
            {({ isSubmitting, errors, values }) => (
              <ContactsForm>
                <ContactsModal
                  isOpen={isModalOpen}
                  onBackgroundClick={setIsModalOpenAction}
                  onEscapeKeydown={setIsModalOpenAction}
                >
                  <p>Dziękujemy, wiadomość została wysłana!</p>
                  <ContactsModalButton
                    onClick={setIsModalOpenAction}
                    color="#ffff"
                    bgColor="#00863d"
                  >
                    Zamknij
                  </ContactsModalButton>
                </ContactsModal>
                <ContactsRow>
                  <ContactsFormColLeft>
                    <ContactsFormLabel htmlFor="topic">Temat</ContactsFormLabel>
                    <ContactsFormField
                      type="text"
                      name="subject"
                      id="topic"
                      className="topic"
                      placeholder="Wpisz temat swojego pytania"
                      error={errors.subject}
                    />
                    <br />
                    <ContactsFormLabel htmlFor="email">
                      E-mail
                    </ContactsFormLabel>
                    <ContactsFormField
                      type="text"
                      name="email"
                      id="email"
                      className="email"
                      placeholder="Podaj adres e-mail używany na platformie REPXPERT"
                      error={errors.email}
                    />
                    <br />
                    <ContactsFormLabel htmlFor="business">
                      Firma
                    </ContactsFormLabel>
                    <ContactsFormField
                      type="text"
                      name="company"
                      id="business"
                      className="business"
                      placeholder="Podaj nazwę firmy zarejestrowanej na platformie REPXPERT"
                      error={errors.company}
                    />
                  </ContactsFormColLeft>
                  <ContactsFormColRight
                    textValue={values.content}
                    customPlaceholder="Wpisz treść swojego pytania"
                  >
                    <ContactsFormLabel htmlFor="question">
                      Pytanie
                    </ContactsFormLabel>
                    <ContactsFormField
                      type="text"
                      name="content"
                      id="question"
                      component="textarea"
                      className="question"
                      error={errors.question}
                    />
                  </ContactsFormColRight>
                </ContactsRow>

                <ContactsRow>
                  <ContactsCol>
                    <ContactsButton
                      as="button"
                      type="submit"
                      color="#000"
                      bgColor="#d3d4d4"
                      disabled={isSubmitting && 'disabled'}
                      loading={isSendingLoader}
                    >
                      <span>Wyślij</span>
                      <span className="spinner-wrapper">
                        <div className="lds-spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </span>
                    </ContactsButton>
                  </ContactsCol>
                </ContactsRow>
              </ContactsForm>
            )}
          </Formik>
        </ContactsFormWrapper>
        <ContactsRow>
          <ContactsColCall>
            <ContactsCallWrapper>
              <ContactsCallTitle>
                MASZ PYTANIA? NIE WAHAJ SIĘ I ZADZWOŃ!
              </ContactsCallTitle>
              <p>
                <ContactsCallPhone href="tel:48222458555">
                  +48 22 245 85 55*
                </ContactsCallPhone>
              </p>
              <ContactsCallNote>
                *opłata według taryfy operatora
              </ContactsCallNote>
            </ContactsCallWrapper>
            <ContactsColCallAnnotation>
              <p>
                Administratorem podanych przez Ciebie w formularzu danych
                osobowych jest Schaeffler sp. z o.o. z siedzibą w Warszawie, ul.
                Szyszkowa 35/37, 02-285 Warszawa. Twoje dane osobowe będą
                przetwarzane w celu udzielenia odpowiedzi na Twoje zgłoszenie
                złożone za pomocą formularza kontaktowego. Więcej informacji na
                temat przet¬warzania danych osobowych, w tym o przysługujących
                Ci prawach, znajduje się w naszej Polityce Prywatności.
              </p>
            </ContactsColCallAnnotation>
          </ContactsColCall>
        </ContactsRow>
      </ContactsContainer>
    </ContactsWrapper>
  )
}

export default Contacts
