import React from 'react'
import styled from 'styled-components'
import { Textfit } from 'react-textfit'

import { ContainerMixin, RowMixin, ColMixin, Button } from './elements'

import backgroundBox3 from '../images/background-box3.svg'
import businessBG from '../images/business-bg.svg'
import Btn1Image from '../images/button.png'

const BusinessWrapper = styled.div`
  height: 100vh;
  position: relative;
  background-color: #212121;
  background-image: url(${backgroundBox3});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  font-family: ${(props) => props.theme.fontLato};
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 50px;
    left: 0;
    right: -0;
    bottom: 0;
    background-image: url(${businessBG});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 50;
  }
`

const BusinessContainer = styled.div`
  ${ContainerMixin}
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  min-height: 100vh;
  z-index: 100;
  @media (max-width: 1199px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (min-width: 1200px) and (max-width: 1460px) and (orientation: landscape) {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media (max-width: 991px) and (orientation: landscape) {
    padding-left: 15%;
    padding-right: 15%;
  }
`

const BusinessRow = styled.div`
  ${RowMixin}
`

const BusinessWrappper = styled(BusinessRow)`
  margin-top: -5%;
  position: relative;
  width: 100%;
  z-index: 150;
`

const BusinessColTitle = styled.div`
  ${ColMixin}
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 65%;
  margin-top: 15%;
  @media (max-width: 767px) {
    margin-top: 18%;
    padding-right: 60%;
  }
  @media (max-width: 575px) {
    margin-top: 25%;
    padding-right: 30%;
  }
`

const BusinessTitle = styled.div`
  font-family: ${(props) => props.theme.fontLato};
  color: ${(props) => props.theme.colorWhite};
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
`

const BusinessColDesc = styled.div`
  ${ColMixin}
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 55%;
  margin-bottom: 12%;
  pointer-events: none;
  @media (max-width: 767px) {
    padding-left: 45%;
  }
  @media (max-width: 575px) {
    padding-left: 12%;
    padding-right: 12%;
    margin-bottom: 30%;
  }
`

const BusinessAuthor = styled.div`
  font-size: 24px;
  font-weight: 400;
  padding-right: 60%;
  margin-top: 15px;
  @media (max-width: 720px) {
    padding-right: 70%;
  }
  @media (max-width: 575px) {
    padding-right: 65%;
  }
`

const BusinessDescTitle = styled.div`
  color: ${(props) => props.theme.colorBlack};
  font-family: 'Oswald';
  font-size: 33px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  @media (min-width: 721px) {
  }
  @media (max-width: 720px) {
    padding-left: auto;
  }
`

const BusinessColButton = styled.div``

const BusinessButton = styled(Button)`
  position: relative;
  display: block;
  color: #00893d;
  padding-right: 0.8em;
  margin-top: 0.6em;
  text-align: left;
  cursor: pointer;

  img {
    height: auto;
    max-width: 50%;
  }
`

const Business = ({ visible }) => {
  return (
    <div className="section">
      <BusinessWrapper>
        <BusinessContainer>
          <BusinessWrappper top>
            <BusinessColTitle>
              <BusinessTitle>
                <Textfit mode="single">
                  Rejestruj punkty <br />
                  z Kuponów Bonusowych <br />
                  REPXPERT i odbieraj <br />
                  nagrody które sprawią, <br />
                  ŻE NIKT CIĘ <br />
                  NIE PRZEOCZY!
                </Textfit>
              </BusinessTitle>
              <BusinessColButton>
                <BusinessButton
                  as="a"
                  href="https://www.repxpert.pl/pl/login?utm_source=niedajsieprzeoczyc&utm_medium=button&utm_campaign=niedajsieprzeoczyc&utm_content=button-business"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="business-PlayButton"
                  className="externalLink"
                >
                  <img src={Btn1Image} />
                </BusinessButton>
              </BusinessColButton>
            </BusinessColTitle>
          </BusinessWrappper>
          <BusinessWrappper bottom>
            <BusinessColDesc className="businessColDesc">
              <BusinessDescTitle>
                <Textfit mode="single">
                  „Prowadzenie biznesu <br />
                  bez reklamy jest jak <br />
                  puszczanie oka <br />
                  do dziewczyny po ciemku. <br />
                  Nikt poza nami nie wie, <br />
                  co robimy."
                </Textfit>
              </BusinessDescTitle>
              <BusinessAuthor>Stuart Henderson</BusinessAuthor>
            </BusinessColDesc>
          </BusinessWrappper>
        </BusinessContainer>
      </BusinessWrapper>
    </div>
  )
}

export default Business
