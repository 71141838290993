import React from 'react'
import styled from 'styled-components'

import handImage from '../images/hand.svg'
import accountLogosBG from '../images/logos.svg'

import {
  ContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  PaddingsContainerMixin,
  Button,
} from './elements'

import logoIcon from '../images/logo3.svg'

const AccountWrapper = styled.div`
  position: relative;
  background-color: #e4e4e4;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: 40%;
  padding-top: 150px;
  padding-bottom: 45px;
  z-index: 80;
  @media (min-width: 768px) {
    background-image: url(${handImage});
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 100px;
  }
  @media (max-width: 575px) {
    padding-top: 80px;
  }
`

const AccountContainer = styled.div`
  ${ContainerMixin}
`

const AccountRow = styled.div`
  ${RowMixin}
`

const AccountCol = styled.div`
  ${ColMixin}
`

const AccountColLogo = styled(AccountCol)`
  flex: 0 0 100%;
  max-width: 100%;
  ${PaddingsContainerMixin}
  padding-right: 15px;
  padding-bottom: 80px;
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 60px;
  }
  @media (max-width: 575px) {
    padding-bottom: 45px;
  }
`

const AccountLogoWrapper = styled.div`
  display: block;
  background-image: url(${logoIcon});
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  height: 65px;
`

const AccountColDesc = styled(AccountCol)`
  ${PaddingsContainerMixin}
  ${DescMixin}
  @media (min-width: 768px) {
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 0;
  }
`

const AccountButtonLink = styled(Button)`
  width: 290px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 260px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 220px;
  }
  @media (max-width: 575px) {
    display: block;
    width: 210px;
    margin-left: auto;
    margin-right: auto;
  }
`

const AccountColLogos = styled(AccountCol)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const AccountLogosWrapper = styled.div`
  display: inline-block;
  background-image: url(${accountLogosBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 220px;
  padding-top: 12%;
  @media (max-width: 767px) {
    margin-bottom: 5%;
  }
  @media (max-width: 575px) {
    display: block;
    width: 180px;
    padding-top: 15%;
    margin-top: 4%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9%;
  }
`

const Account = () => {
  return (
    <AccountWrapper id="account">
      <AccountContainer>
        <AccountRow>
          <AccountColLogo>
            <AccountLogoWrapper />
          </AccountColLogo>
        </AccountRow>
        <AccountRow>
          <AccountColDesc>
            <p>
              Chcesz wygrywać częściej, brać udział w Akcjach Specjalnych,<br />
              otrzymywać dodatkowe korzyści, uczestniczyć<br />
              w szkoleniach online i webinarach <strong>zupełnie za darmo</strong>?
            </p>
            <p>
              Odkryj praktyczny portal dla swojego warsztatu i zyskuj
              dodatkowo rejestrując punkty z Kuponów Bonusowych
              produktów firmy Schaeffler!
            </p>
            <p>
              Wejdź na <strong><a href="https://www.repxpert.pl?utm_source=niedajsieprzeoczyc&utm_medium=button&utm_campaign=niedajsieprzeoczyc&utm_content=button-account" id="account-AccountRepxpertLink" className="externalLink" target="_blank" rel="noopener noreferrer">www.repxpert.pl</a></strong> i załóż konto jeszcze dziś!
            </p>
          </AccountColDesc>
          {true && <AccountColLogos>
            <AccountLogosWrapper />
          </AccountColLogos>}
        </AccountRow>
        <AccountRow>
          <AccountColDesc>
            <AccountButtonLink
              href="https://www.repxpert.pl?utm_source=niedajsieprzeoczyc&utm_medium=button&utm_campaign=niedajsieprzeoczyc&utm_content=button-register-account"
              color="#fff"
              bgColor="#00863d"
              target="_blank"
              rel="noopener noreferrer"
              id="account-AccountButtonLink"
              className="externalLink"
            >
              zakładam konto
            </AccountButtonLink>
          </AccountColDesc>
        </AccountRow>
      </AccountContainer>
    </AccountWrapper>
  )
}

export default Account
