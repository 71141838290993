import React from 'react'
import { Textfit } from 'react-textfit'
import styled, { css } from 'styled-components'

import { ColMixin, ContainerMixin, RowMixin } from './elements'

import backgroundBox1 from '../images/background-box1.svg'
import titleBG from '../images/title-box1.svg'
import introCircleBG from '../images/intro-circle.svg'
import Btn1Image from '../images/button.png'

const PlayButton = styled.a`
  display: block;
  margin-top: 15px;
  img {
    @media (max-width: 575px) {
      display: block;
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const IntroWrapper = styled.div`
  font-family: ${(props) => props.theme.fontLato};
  /* font-weight: 900; */
  position: relative;
  /* background-color: #212121; */
  background-image: url(${backgroundBox1});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
`

const IntroContainer = styled.div`
  ${ContainerMixin}
  position: relative;
  display: flex;
  align-items: flex-end;
  /* max-width: 1920px; */
  min-height: 100vh;
  z-index: 100;
  @media (max-width: 1199px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
  @media (min-width: 1200px) and (max-width: 1460px) and (orientation: landscape) {
  }
  @media (max-width: 991px) and (orientation: landscape) {
    padding-left: 5%;
    padding-right: 20%;
  }
  &:after {
    /* content: ''; */
    color: #000;
    top: 10%;
    left: 50%;
    display: block;
    width: 70%;
    height: 70%;
    position: absolute;
    background-image: url(${introCircleBG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @keyframes animateCircle {
      0% {
        transform: translateX(-50%) rotate(0deg);
      }
      100% {
        transform: translateX(-50%) rotate(360deg);
      }
    }
    animation-name: animateCircle;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    @media (max-width: 767px) {
      top: 7%;
      width: 65%;
      height: 65%;
    }
    @media (max-width: 575px) {
      top: -3%;
    }
  }
`

const IntroWrappper = styled.div`
  ${RowMixin}
  width: 100%;
  position: relative;
  z-index: 150;
`

const IntroTitle = styled.h1`
  position: absolute;
  top: calc(6% + 100px);
  left: 10.5%;
  bottom: 0;
  display: block;
  width: 25%;
  background-image: url(${titleBG});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  color: transparent;
  @media (max-width: 575px) {
    top: 100px;
    right: 0;
    bottom: 55%;
    width: 100%;
  }
`

const IntroColBottom = styled.div`
  ${ColMixin}
  color: ${(props) => props.theme.colorWhite}!important;
  flex: 0 0 33%;
  max-width: 33%;
  padding-left: 4.5%;
  margin-left: 60.5%;
  margin-bottom: 8%;
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-left: 67.25%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-left: 71%;
    margin-bottom: 10%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    flex: 0 0 35%;
    max-width: 35%;
    margin-left: 55%;
    margin-bottom: 8%;
  }
  @media (max-width: 767px) {
    flex: 0 0 60%;
    max-width: 60%;
    padding-top: 8%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }
  @media (max-width: 575px) {
    flex: 0 0 70%;
    max-width: 70%;
    padding-top: 5%;
    margin-bottom: 36%;
  }
`

export const IntroTextMixin = css/* styled */ `
  font-family: ${(props) => props.theme.fontLato};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0;
`

const IntroText = styled.div`
  ${IntroTextMixin}
  color: ${(props) => props.theme.colorWhite}!important;
  text-transform: none;

  &:first-of-type {
    margin-bottom: 8%;
  }
  span {
    color: ${(props) => props.theme.colorGreen};
  }
`

const IntroBottomLogosWrapper = styled.div`
  display: block;

  &.move {
    opacity: 0;
  }

  img {
    height: auto;
    width: 50%;
  }
`

const Intro = (props) => {
  return (
    <div className="section">
      <IntroWrapper>
        <IntroContainer>
          <IntroTitle>Nie daj się przeoczyć 2.0</IntroTitle>
          <IntroWrappper>
            <IntroColBottom className="introColBottom">
              <IntroText>
                <Textfit mode="single">
                  Kupuj produkty marek LuK, INA, FAG,
                  <br />
                  Rejestruj kupony bonusowe
                  <br />
                  w portalu REPXPERT
                  <br />
                  i odbieraj cenne nagrody!
                  <br />
                  <br />
                  Weź udział w akcji klikając:
                </Textfit>
              </IntroText>
              <IntroBottomLogosWrapper>
                <PlayButton
                  href="https://www.repxpert.pl/pl/login?utm_source=niedajsieprzeoczyc&utm_medium=button&utm_campaign=niedajsieprzeoczyc&utm_content=button-intro"
                  id="intro-PlayButton"
                  target="_blank"
                  className="externalLink"
                >
                  <img src={Btn1Image} />
                </PlayButton>
              </IntroBottomLogosWrapper>
            </IntroColBottom>
          </IntroWrappper>
        </IntroContainer>
      </IntroWrapper>
    </div>
  )
}

export default Intro
