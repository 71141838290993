import React, { useState, useEffect } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'

import Layout from '../components/layout'
import Intro from '../components/intro'
import Quality from '../components/quality'
import Business from '../components/business'
import Account from '../components/account'
import Awards from '../components/awards2'
import Contacts from '../components/contacts'
import Footer from '../components/footer'
import Navbar from '../components/navbar'

const IndexPage = (props) => {
  const [visible, setVisible] = useState(0)
  const [visibleEffects, setVisibleEffects] = useState(false)
  const [isBox, setIsBox] = useState(false)
  const [fullpageApi, setFullpageApi] = useState()
  const [autoScroll, setAutoScroll] = useState(true)

  const changeVisibleEffects = (isEffect) => {
    setVisibleEffects(isEffect)
  }

  useEffect(() => {
    if (!fullpageApi) return
    fullpageApi.setAutoScrolling(autoScroll)
  }, [fullpageApi, autoScroll])

  useEffect(() => {
    if (props.location && fullpageApi && props.location.hash === '#awards') {
      fullpageApi.moveTo(4)
    }
  }, [fullpageApi])

  return (
    <Layout title="Schaeffler - Nie daj się przeoczyć!" description="">
      <Navbar
        page="index"
        visibleEffects={visibleEffects}
        isBox={!visibleEffects}
        changeSlide={(number) => fullpageApi.moveTo(number)}
      />

      <ReactFullpage
        licenseKey="CB4A9143-115B4955-831F7A5F-9FECCF7C"
        scrollOverflow={true}
        onLeave={(section, origin) => {
          if (origin.index > 3) {
            setVisibleEffects(true)
          } else if (visibleEffects) {
            setVisibleEffects(false)
          }
        }}
        render={({ state, fullpageApi }) => {
          if (fullpageApi) {
            setFullpageApi(fullpageApi)
          }

          return (
            <ReactFullpage.Wrapper>
              <Intro />
              <Quality onClick={() => fullpageApi.moveTo(4)} />
              <Business />
              <Awards />
              <div className="section">
                <Account />
                <Contacts />
                <Footer />
              </div>
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </Layout>
  )
}

export default IndexPage
