import React from 'react'
import { Textfit } from 'react-textfit'
import styled, { css } from 'styled-components'

import { ColMixin, ContainerMixin, RowMixin } from './elements'

import backgroundBox4 from '../images/background-box4.svg'
import triangleBG from '../images/triangle4.svg'
import awardsLogosBG from '../images/awards-title-logo-bg.svg'
import awardsBG from '../images/award-bg.svg'
import awardExpectBG from '../images/awards2/award-expect.png'
import awardNoBG from '../images/awards2/award-no.png'
import award22BG from '../images/awards2/award4.png'

const AwardsWrapper = styled.div`
  height: 100vh;
  font-family: ${(props) => props.theme.fontLato};
  position: relative;
  background-color: #212121;
  background-image: url(${backgroundBox4});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 50px;
    left: 0;
    right: -0;
    bottom: 0;
    background-image: url(${triangleBG});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 50;
  }
`

const AwardsContainer = styled.div`
  ${ContainerMixin}
  position: relative;
  min-height: 100vh;
  z-index: 100;
  @media (min-width: 1921px) {
    max-width: 1920px;
  }
`

const AwardsTopWrappper = styled.div`
  ${RowMixin}
  position: relative;
  margin-top: 150px;
  z-index: 150;
  @media (max-width: 575px) {
    margin-top: 125px;
  }
`

const AwardsColLeft = styled.div`
  ${ColMixin}
  color: ${(props) => props.theme.colorBlack};
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 6%;
  padding-right: 12%;
  @media (min-width: 576px) and (max-width: 1199px) {
    padding-top: 8%;
  }
  @media (max-width: 575px) {
    padding-top: 22%;
    padding-left: 4%;
    padding-right: 0;
  }
  .title {
    font-family: 'Oswald';
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    padding-right: 50%;
    padding-bottom: 2.5%;

    @media (max-width: 575px) {
      padding-right: 35%;
    }
  }
`

const PDesc = styled.div`
  line-height: 1.3;
`

const AwardsColRight = styled.div`
  ${ColMixin}
  color: ${(props) => props.theme.colorWhite};
  font-weight: 300;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 575px) {
    padding-left: 0;
  }
  .text {
    position: relative;
    font-weight: 400;
    text-align: right;
    line-height: 1.3;
    padding-top: 1.5%;
    padding-left: 33%;
    padding-right: 35%;
    padding-bottom: 3.5%;
    margin-bottom: 0;
    @media (max-width: 575px) {
      padding-left: 10%;
      padding-right: 34%;
      margin-bottom: 5%;
    }
    &:after {
      /* content: ''; */
      position: absolute;
      top: 5%;
      right: 0;
      display: block;
      width: 23%;
      height: 92%;
      background-image: url(${awardsLogosBG});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @keyframes animateAwardCircle {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      animation-name: animateAwardCircle;
      animation-duration: 15s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      @media (max-width: 575px) {
        width: 34%;
      }
    }
  }
`

const AwardsBottomWrappper = styled.div`
  ${RowMixin}
  position: relative;
  margin-top: 3%;
  margin-left: -14.25%;
  margin-right: -14.25%;
  z-index: 150;
  @media (max-width: 575px) {
    margin-top: 10%;
    margin-left: -75%;
    margin-right: -75%;
  }
`

const AwardsColCard = styled.div`
  ${ColMixin}
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 1.8%;
  padding-right: 1.8%;
  text-align: center;
  .innerWrapper {
    height: 100%;
    visibility: hidden;
    background-color: ${(props) => props.theme.colorWhite};
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    padding-top: 115%;
    border: 1px solid #3f3f3f;
    ${(props) =>
      props.number === 2 &&
      `
      background-image: url(${award22BG});
    `}
    ${(props) =>
      props.expected &&
      `
      background-color: transparent;
      background-image: url(${awardExpectBG});
      background-position: center;
      background-size: 65%;
    `}
    ${(props) =>
      props.no &&
      `
      background-color: transparent;
      background-image: url(${awardNoBG});
      background-position: center;
      background-size: 65%;
    `}
    ${(props) =>
      props.center &&
      `
      border-color: #7f7f7f;
    `}
    ${(props) =>
      props.visible &&
      `
      visibility: visible;
    `}
    .descWrapper {
      width: 100%;
      .title {
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        padding-left: 14%;
        padding-right: 14%;
        padding-bottom: 7%;

        small {
          color: ${(props) => props.theme.colorGreen};
          display: block;
          font-weight: 400;
          margin-bottom: 5px;
          margin-top: -15px;
        }
      }
      .title-green {
        color: ${(props) => props.theme.colorGreen} !important;
        font-family: 'Lato' !important;
        text-transform: none;
        font-weight: 400;
      }
      .subTitle {
        font-size: 12px;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 4%;
      }
      .date {
        background-color: #00893d;
        color: ${(props) => props.theme.colorWhite};
        font-weight: 400;
        padding-top: 1.3%;
        padding-left: 35%;
        padding-right: 35%;
        padding-bottom: 1.3%;
      }
    }
  }
`

export const AwardsTextMixin = css/* styled */ `
  font-family: ${(props) => props.theme.fontLato};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 0;
`

const AwardsBottomDivider = styled.div`
  padding-top: 4.5%;
  background-color: ${(props) => props.theme.colorBlack};
  @media (max-width: 575px) {
    padding-top: 1.5%;
  }
`

const Awards = (props) => {
  return (
    <div className="section">
      <AwardsWrapper>
        <AwardsContainer>
          <AwardsTopWrappper>
            <AwardsColLeft className="awardsColLeft">
              <div className="title">
                <Textfit mode="single">
                  do wygrania <br />
                  aż 500 nagród!
                </Textfit>
              </div>
              <PDesc>
                <Textfit mode="single">
                  Co dwa tygodnie czeka na Ciebie inna nagroda!
                  <br />
                  Nie daj się zaskoczyć i zbierz je wszystkie!
                </Textfit>
              </PDesc>
            </AwardsColLeft>
            <AwardsColRight>
              <div className="text">
                <Textfit mode="single">
                  ilustracje nagród
                  <br />
                  są użyte wyłącznie
                  <br />w celach poglądowych
                </Textfit>
              </div>
            </AwardsColRight>
          </AwardsTopWrappper>
          <AwardsBottomWrappper>
            <AwardsColCard visible no number={1}>
              <div className="innerWrapper">
                <div className="descWrapper">
                  <div className="title title-green">
                    {/* <Textfit mode="single">
                    dowiesz się już wkrótce!
                    </Textfit> */}
                  </div>
                </div>
              </div>
            </AwardsColCard>
            <AwardsColCard visible no number={1}>
              <div className="innerWrapper">
                <div className="descWrapper">
                  <div className="title title-green">
                    {/* <Textfit mode="single">
                    dowiesz się już wkrótce!
                    </Textfit> */}
                  </div>
                </div>
              </div>
            </AwardsColCard>
            <AwardsColCard visible center number={2}>
              <div className="innerWrapper">
                <div className="descWrapper">
                  <div className="title">
                    <Textfit mode="single">
                      <small>100 X</small>
                      Car Book
                    </Textfit>
                  </div>
                  <div className="subTitle">
                    przypomnij swoim klientom<br/>o najbliższym serwisie<br/>dzięki książeczkom Car Book!
                  </div>
                  <div className="date">
                    <Textfit mode="single">17.05-30.05.2021</Textfit>
                  </div>
                </div>
              </div>
            </AwardsColCard>
            <AwardsColCard invisible expected number={2}>
              <div className="innerWrapper">
                <div className="descWrapper">
                  <div className="title title-green">
                    {/* <Textfit mode="single">
                    dowiesz się już wkrótce!
                    </Textfit> */}
                  </div>
                </div>
              </div>
            </AwardsColCard>
            <AwardsColCard invisible number={3} />
          </AwardsBottomWrappper>
        </AwardsContainer>
      </AwardsWrapper>
    </div>
  )
}

export default Awards
