import React from 'react'
import styled, { css } from 'styled-components'
import { Textfit } from 'react-textfit'
import { Link as LinkScroll } from 'react-scroll'

import { ContainerMixin, RowMixin, ColMixin, Button } from './elements'

import backgroundBox2 from '../images/background-box2.svg'
import triangleBG from '../images/triangle2.svg'
import Btn2Image from '../images/button-green.png'

const QualityWrapper = styled.div`
  font-family: ${(props) => props.theme.fontLato};
  position: relative;
  background-color: #212121;
  background-image: url(${backgroundBox2});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  &.blink {
    &:before {
      opacity: 1;
      animation: blink 0.2s linear;
    }
  }
  &:before {
    content: '';
    overflow: hidden;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-image: url(${triangleBG});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: 50;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const QualityContainer = styled.div`
  ${ContainerMixin}
  display: flex;
  align-items: center;
  min-height: 100vh;
  z-index: 100;
  @media (max-width: 1199px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    /* padding-top: 80px; */
  }
  @media (max-width: 575px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (min-width: 1200px) and (max-width: 1460px) and (orientation: landscape) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (max-width: 991px) and (orientation: landscape) {
    /* padding-left: 25%;
    padding-right: 25%; */
  }
`

const QualityWrappper = styled.div`
  ${RowMixin}
  width: 100%;
  position: relative;
  z-index: 150;
`

const QualityColTopLeft = styled.div`
  ${ColMixin}
  color: ${(props) => props.theme.colorWhite};
  flex: 0 0 31.5%;
  max-width: 31.5%;
  padding-left: 10%;
  @media (min-width: 768px) and (max-width: 991px) {
    position: relative;
    top: -100px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    position: relative;
    top: -100px;
  }
  @media (max-width: 575px) {
    position: relative;
    top: -85px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  p {
    padding-right: 8%;
  }
  .desc {
    padding-right: 12%;
  }
`

const QualityColMiddle = styled.div`
  ${ColMixin}
  flex: 0 0 100%;
  max-width: 100%;
`

const QualityRowMiddle = styled.div`
  ${RowMixin}
`

const QualityColMiddleTop = styled.div`
  ${ColMixin}
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  margin-top: 6%;
  @media (max-width: 575px) {
    margin-top: 12%;
    padding-left: 5%;
    padding-right: 5%;
  }
`

export const QualityTextMixin = css/* styled */ `
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 0;
  span {
    span {
      color: ${(props) => props.theme.colorGreen};
    }
  }
`

const QualityTitle = styled.h2`
  ${QualityTextMixin}
  font-family: 'Oswald';
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  @media (max-width: 575px) {
  }
`

const QualityText = styled.div`
  ${QualityTextMixin}
`

const QualityTextMiddle = styled(QualityText)`
  position: relative;
  color: ${(props) => props.theme.colorBlack};
  @keyframes animateQualityText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation-name: animateQualityText;
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  padding-top: 12%;
  margin-bottom: 5%;
  z-index: 50;

  div {
    margin-bottom: 15px;
    &.title {
      font-family: 'Oswald';
      font-weight: 700;
      padding-right: 27%;
      margin-bottom: 10px;
    }
    &.desc {
      padding-right: 14%;
    }
  }
  .buttonWrapper {
    padding-right: 60%;
    a {
      padding: 0;
      padding-bottom: 0;
      @media (min-width: 575px) {
        margin-left: -10%;
      }
    }
  }
`

const QualityButton = styled(Button)`
  position: relative;
  display: block;
  color: #00893d;
  padding-right: 1em;
  cursor: pointer;
  @media (max-width: 575px) {
  }

  img {
    height: auto;
    max-width: 100%;
  }
`

const Quality = ({ onClick, visible }) => {
  return (
    <div className="section">
      <QualityWrapper>
        <QualityContainer>
          <QualityWrappper>
            <QualityColTopLeft>
              <QualityTitle>
                <Textfit mode="single">
                  Jakość <span>&nbsp;</span>usług
                </Textfit>
              </QualityTitle>
              <div className="desc">
                <Textfit mode="single">
                  to najlepszy rodzaj
                  <br />
                  reklamy, jednak
                </Textfit>
              </div>
            </QualityColTopLeft>
            <QualityColMiddle>
              <QualityRowMiddle>
                <QualityColMiddleTop>
                  <QualityTextMiddle className="qualityTextMiddle">
                    <div className="title">
                      <Textfit mode="single">
                        UCZYŃ KLIENTA BOHATEREM
                        <br /> TWOJEJ HISTORII,
                      </Textfit>
                    </div>
                    <div className="desc">
                      <Textfit mode="single">
                        docierając do niego niebanalnymi działaniami
                        <br />
                        reklamowymi!
                      </Textfit>
                    </div>
                    <div className="desc">
                      <Textfit mode="single">
                        Nie daj się przeoczyć i spraw, aby nowi klienci <br />
                        chcieli poznać Ciebie.
                      </Textfit>
                    </div>
                    <div className="buttonWrapper">
                      <QualityButton onClick={onClick}>
                        <img src={Btn2Image} />
                      </QualityButton>
                    </div>
                  </QualityTextMiddle>
                </QualityColMiddleTop>
              </QualityRowMiddle>
            </QualityColMiddle>
          </QualityWrappper>
        </QualityContainer>
      </QualityWrapper>
    </div>
  )
}

export default Quality
